<template>
    <b-card
        tag="article"
        no-body
    >
        <b-link :to="{ name: 'sikayet-detaylari', params: { id: item.id } }">
            <swiper
                class="swiper-navigations"
                :options="swiperOptions"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            >
                <swiper-slide :key="item.id+image.path" v-for="image in item.files">
                    <b-img
                        v-if="image.media_type === 'image'"
                        class="card-img-top"
                        :src="image.full_path"
                        fluid
                    />
                    <video
                        v-if="image.media_type === 'video'"
                        controls
                        class="card-img-top"
                    >
                        <source
                            :src="image.full_path"
                            type="video/mp4"
                        >
                        {{ $t("Your browser does not support video.") }}
                    </video>
                    <audio
                        v-if="image.media_type === 'audio'"
                        controls
                        class="card-img-top"
                    >
                        <source
                            :src="image.full_path"
                            type="audio/wav"
                        >
                        <source
                            :src="image.full_path"
                            type="audio/mp3"
                        >
                        {{ $t('Your browser does not support audio.') }}
                    </audio>
                </swiper-slide>

                <!-- Add Arrows -->
                <div
                    v-show="item.files>1"
                    slot="button-next"
                    class="swiper-button-next"
                />
                <div
                    v-show="item.files>1"
                    slot="button-prev"
                    class="swiper-button-prev"
                />
                <div
                    v-show="item.files>1"
                    slot="pagination"
                    class="swiper-pagination"
                />
            </swiper>
        </b-link>
        <b-card-body>
            <b-card-title>
                <b-link
                    :to="{ name: 'sikayet-detaylari', params: { id: item.id } }"
                    class="blog-title-truncate text-body-heading"
                >
                    {{ item.title ? item.title : "Şikayet başlığı" }}
                </b-link>
            </b-card-title>
            <b-media no-body>
                <b-media-aside
                    vertical-align="center"
                    class="mr-50"
                >
                    <b-avatar
                        size="24"
                        :src="item.user.profile_image"
                    />
                </b-media-aside>
                <b-media-body>
                    <!--                            <small class="text-muted mr-50">by</small>-->
                    <small>
                        <b-link
                            :to="{ name: 'sikayet-detaylari', params: { id: item.id } }"
                            class="text-body"
                        >
                            {{ item.user.first_name }} {{ item.user.last_name }}
                        </b-link>
                    </small>
                    <span class="text-muted ml-75 mr-50">|</span>
                    <small class="text-muted">{{ item.created_at }}</small>
                </b-media-body>
            </b-media>
            <div class="my-1 py-25">
                <b-link v-if="item.category">
                    <b-badge
                        pill
                        class="mr-75"
                        variant="warning"
                    >
                        {{ item.category.name }}
                    </b-badge>
                </b-link>
                <b-link>
                    <b-badge
                        pill
                        class="mr-75"
                        :variant="statusColor(item.status)"
                    >
                        {{ $t(item.status) }}
                    </b-badge>
                </b-link>

            </div>
            <b-card-text class="blog-content-truncate">
                {{ item.description.slice(0, 50) }}...
            </b-card-text>
            <hr>
            <div class="d-flex justify-content-between align-items-center">
                <b-button
                    variant="info"
                    :href="'https://www.google.com/maps/search/?api=1&query='+item.lat+','+item.long"
                    target="_blank"
                >
                    <div class="d-flex align-items-center text-body">
                        <feather-icon
                            icon="MapPinIcon"
                            class="mr-25  text-white"
                        />
                        <span class="font-weight-bold  text-white">{{ $t('Location') }}</span>
                    </div>
                </b-button>
                <!--                            <b-link-->
                <!--                                :href="'//' + 'www.google.com/maps/@?api=1&map_action=map&basemap=satellite&center='+item.lat+','+item.long"-->

                <!--                                target='_blank'>-->

                <!--                                <div class="d-flex align-items-center text-body">-->
                <!--                                    <feather-icon-->
                <!--                                        icon="MapPinIcon"-->
                <!--                                        class="mr-50"-->
                <!--                                    />-->
                <!--                                    <span class="font-weight-bold">{{ $t('Location') }}</span>-->
                <!--                                </div>-->
                <!--                            </b-link>-->
                <b-button variant="success" :to="{ name: 'chat-page', params: { id: item.id } }"
                          class="font-weight-bold">
                    <div class="d-flex align-items-center text-body">
                        <feather-icon
                            icon="SendIcon"
                            class="mr-50 text-white"
                        />
                        <span class="font-weight-bold text-white">{{ $t('Go to chat') }}</span>
                    </div>
                </b-button>
                <!--              <b-link-->
                <!--                :to="{ name: 'chat-page', params: { id: item.id } }"-->
                <!--                class="font-weight-bold"-->
                <!--              >-->
                <!--                <div class="d-flex align-items-center text-body">-->
                <!--                  <feather-icon-->
                <!--                    icon="SendIcon"-->
                <!--                    class="mr-50"-->
                <!--                  />-->
                <!--                  <span class="font-weight-bold">{{ $t('Go to chat') }}</span>-->
                <!--                </div>-->
                <!--                &lt;!&ndash;                                {{ $t('Go to chat') }}&ndash;&gt;-->
                <!--              </b-link>-->
            </div>
        </b-card-body>
    </b-card>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import {
    BRow,
    BCol,
    BCard, BButton,
    BFormInput,
    BCardText,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BImg,
    BCardBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BPagination,
    BFormRadioGroup,
    BFormRadio,
} from 'bootstrap-vue'

export default {
    name: "gridCard",
    components: {
        Swiper,
        SwiperSlide,
        BRow,
        BCol,
        BCard,
        BButton,
        BFormInput,
        BCardText,
        BCardBody,
        BCardTitle,
        BMedia,
        BAvatar,
        BMediaAside,
        BMediaBody,
        BLink,
        BBadge,
        BFormGroup,
        BInputGroup,
        BInputGroupAppend,
        BImg,
        BPagination,
        BFormRadioGroup,
        BFormRadio,
    },
    data() {
        return {
            swiperOptions: {
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            },
            complaintsStatus: [
                {title: 'submited', icon: 'FilePlusIcon'},
                {title: 'in_process', icon: 'ClockIcon'},
                {title: 'done', icon: 'CheckSquareIcon'},
                {title: 'canceled', icon: 'XCircleIcon'},
            ],
        };
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        statusColor(status) {
            if (status === 'in_process') return 'light-info'
            if (status === 'canceled') return 'light-danger'
            if (status === 'submited') return 'light-primary'
            // if (status === 'Video') return 'light-warning'
            if (status === 'done') return 'light-success'
            return 'light-primary'
        },
    },
}
</script>

<style scoped>

</style>
