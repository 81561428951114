<template>
    <b-card
        class="ecommerce-card"
        no-body
    >
        <b-row class="match-height">
            <b-col md="3" class="overflow-hidden slider-container position-relative">
                <div class="status-border" :class="'bg-'+cardStatusBorderColor(item.status)">
                </div>
                <b-link :to="{ name: 'sikayet-detaylari', params: { id: item.id } }" >
                    <swiper
                        class="swiper-navigations w-100"
                        :options="swiperOptions"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    >
                        <swiper-slide :key="item.id+image.path" v-for="image in item.files">
                            <b-img
                                v-if="image.media_type === 'image'"
                                class="card-img-top"
                                :src="image.full_path"
                                fluid
                            />
                            <video
                                v-if="image.media_type === 'video'"
                                controls
                                class="card-img-top"
                            >
                                <source
                                    :src="image.full_path"
                                    type="video/mp4"
                                >
                                {{ $t("Your browser does not support video.") }}
                            </video>
                            <audio
                                v-if="image.media_type === 'audio'"
                                controls
                                class="card-img-top"
                            >
                                <source
                                    :src="image.full_path"
                                    type="audio/wav"
                                >
                                <source
                                    :src="image.full_path"
                                    type="audio/mp3"
                                >
                                {{ $t('Your browser does not support audio.') }}
                            </audio>
                        </swiper-slide>

                        <!-- Add Arrows -->
                        <div
                            v-show="item.files>1"
                            slot="button-next"
                            class="swiper-button-next"
                        />
                        <div
                            v-show="item.files>1"
                            slot="button-prev"
                            class="swiper-button-prev"
                        />
                        <div
                            v-show="item.files>1"
                            slot="pagination"
                            class="swiper-pagination"
                        />
                    </swiper>
                </b-link>

            </b-col>
            <b-col md="6" class="py-2">
                <div class="">
                    <!--    Title  -->
                    <b-link :to="{ name: 'sikayet-detaylari', params: { id: item.id } }">
                        <h4>{{ item.title ? item.title : "Şikayet başlığı" }}</h4>
                    </b-link>

                    <!--    User & Date  -->
                    <b-media no-body class="mt-1">
                        <b-media-aside
                            vertical-align="center"
                            class="mr-50"
                        >
                            <b-avatar
                                size="24"
                                :src="item.user.profile_image"
                            />
                        </b-media-aside>
                        <b-media-body>
                            <small>
                                <b-link
                                    :to="{ name: 'sikayet-detaylari', params: { id: item.id } }"
                                    class="text-body"
                                >
                                    {{ item.user.first_name }} {{ item.user.last_name }}
                                </b-link>
                            </small>
                            <span class="text-muted ml-75 mr-50">|</span>
                            <small class="text-muted">{{ item.created_at }}</small>
                        </b-media-body>
                    </b-media>
                    <!--    Status  -->
                    <div class="my-1 py-25">
                        <b-link v-if="item.category">
                            <b-badge
                                pill
                                class="mr-75"
                                variant="warning"
                            >
                                {{ item.category.name }}
                            </b-badge>
                        </b-link>
                        <b-link>
                            <b-badge
                                pill
                                class="mr-75"
                                :variant="statusColor(item.status)"
                            >
                                {{ $t(item.status) }}
                            </b-badge>
                        </b-link>
                    </div>
                    <!--    Description  -->
                    <div>
                        <p>{{ item.description.slice(0, 120) }}...</p>
                    </div>

                </div>
            </b-col>
            <b-col md="3" class="border-left">
                <div class="h-100 d-flex flex-column align-items-center justify-content-center">
                    <b-button
                        variant="light"
                        :href="'https://www.google.com/maps/search/?api=1&query='+item.lat+','+item.long"
                        target="_blank"
                        tag="a"
                        class="btn-wishlist mb-2"
                    >
                        <feather-icon
                            icon="MapPinIcon"
                            class="mr-25 "
                        />
                        <span>{{ $t('Location') }}</span>
                    </b-button>
                    <b-button
                        badge="6"
                        variant="gradient-success"
                        tag="a"
                        class="btn-cart position-relative"
                        :to="{ name: 'chat-page', params: { id: item.id } }"
                    >
                        <notification-badge :messagesCount="item.message_not_road_count"/>
                        <feather-icon
                            icon="SendIcon"
                            class="mr-50 text-white"
                        />
                        <span>{{ $t('Go to chat') }}</span>
                    </b-button>
                </div>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import {
    BRow,
    BCol,
    BCard, BButton,
    BFormInput,
    BCardText,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BImg,
    BCardBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BPagination,
    BFormRadioGroup,
    BFormRadio,
} from 'bootstrap-vue'
import notificationBadge from "@/views/pages/public/complaints/component/notificationBadge";
export default {
    name: "listCard",
    components:{
        Swiper,
        SwiperSlide,
        BRow,
        BCol,
        BCard,
        BButton,
        BFormInput,
        BCardText,
        BCardBody,
        BCardTitle,
        BMedia,
        BAvatar,
        BMediaAside,
        BMediaBody,
        BLink,
        BBadge,
        BFormGroup,
        BInputGroup,
        BInputGroupAppend,
        BImg,
        BPagination,
        BFormRadioGroup,
        BFormRadio,
        notificationBadge
    },
    data(){
        return{
            swiperOptions: {
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            },
            complaintsStatus: [
                {title: 'submited', icon: 'FilePlusIcon'},
                {title: 'in_process', icon: 'ClockIcon'},
                {title: 'done', icon: 'CheckSquareIcon'},
                {title: 'canceled', icon: 'XCircleIcon'},
            ],
        };
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    methods:{
        statusColor(status) {
            if (status === 'in_process') return 'light-info'
            if (status === 'canceled') return 'light-danger'
            if (status === 'submited') return 'light-primary'
            // if (status === 'Video') return 'light-warning'
            if (status === 'done') return 'light-success'
            return 'light-primary'
        },
        cardStatusBorderColor(status){
            if (status === 'in_process') return 'info'
            if (status === 'canceled') return 'danger'
            if (status === 'submited') return 'primary'
            // if (status === 'Video') return 'light-warning'
            if (status === 'done') return 'success'
            return 'primary'
        },
    },
}
</script>

<style lang="scss">
.slider-container{
    height: auto;
}
.status-border{
    position: absolute;
    z-index: 2;
    width: 0.5em;
    height: 100%;
    border-top-left-radius: 0.428rem;
    border-bottom-left-radius: 0.428rem;
}



</style>
