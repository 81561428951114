<template>
    <content-with-sidebar class="blog-wrapper">
        <b-row class="blog-list-wrapper">
            <!--    If there is no Items    -->
            <b-col
                v-if="complaints.length<1"
                md-12
            >
                <div class="d-flex justify-content-center mt-5">
                    <p class="col-lg-6 text-center font-weight-bold h3">
                        {{
                            $t("There are no complaints with these conditions. Please change search parameters or Congratulations there are no complaints.")
                        }}
                    </p>
                </div>
            </b-col>
            <!--    Print Items     -->
            <b-col md="12" v-for="item in complaints"
                   :key="item.id+'_list_item'" v-if="itemView==='list-view'">
                <ListCard :item="item"/>
            </b-col>
            <b-col
                v-for="item in complaints"
                :key="item.id+'_grid_item'"
                md="6"
                lg="6"
                xl="6" v-if="itemView==='grid-view'"
            >
                <GridCard :item="item"/>
            </b-col>
            <!--     Pagination       -->
            <b-col
                v-if="complaints.length>0"
                cols="12"
            >
                <!-- pagination -->
                <div class="my-2">
                    <b-pagination
                        v-model="currentPage"
                        align="center"
                        :per-page="$store.state.complaints.complaintsPagination.per_page"
                        :total-rows="$store.state.complaints.complaintsPagination.total"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                        <template #prev-text>
                            <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                            />
                        </template>
                        <template #next-text>
                            <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                            />
                        </template>
                    </b-pagination>
                </div>
            </b-col>
        </b-row>
        <!-- sidebar -->
        <div
            slot="sidebar"
            class="blog-sidebar py-2 py-lg-0"
        >
            <div class="mb-2 justify-content-center d-none d-lg-flex">
                <b-form-radio-group
                    v-model="itemView"
                    class=" list item-view-radio-group w-100"
                    buttons
                    size="sm"
                    button-variant="outline-primary"
                >
                    <b-form-radio
                        v-for="option in itemViewOptions"
                        :key="option.value"
                        :value="option.value"
                    >
                        <feather-icon
                            :icon="option.icon"
                            size="18"
                        />
                    </b-form-radio>
                </b-form-radio-group>
            </div>
            <!-- input search -->
            <b-form-group class="blog-search">
                <b-input-group class="input-group-merge">
                    <b-form-input
                        id="search-input"
                        v-model="search_query"
                        :placeholder="$t('Type to Search')"
                        @keyup.enter="getFilteredComplaints"
                    />
                    <b-input-group-append
                        class="cursor-pointer"
                        is-text
                        @click="getFilteredComplaints()"
                    >
                        <feather-icon
                            icon="SearchIcon"
                        />
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
            <!--/ input search -->

            <!-- categories -->
            <div class="blog-categories mt-2">
                <h6 class="section-label mb-1">
                    {{ $t('Categories') }}
                </h6>

                <div
                    v-for="status in complaintsStatus"
                    :key="status.icon"
                    class="d-flex justify-content-start align-items-center mb-75"
                    @click="getFilteredComplaints(null,status.title)"
                >
                    <b-link>
                        <b-avatar
                            rounded
                            size="32"
                            :variant="statusColor(status.title)"
                            class="mr-75"
                        >
                            <feather-icon
                                :icon="status.icon"
                                size="16"
                            />
                        </b-avatar>
                    </b-link>
                    <b-link>
                        <div class="blog-category-title text-body">
                            {{ $t(status.title) }}
                        </div>
                    </b-link>
                </div>
            </div>
            <!--/ categories -->
        </div>
        <!--/ sidebar -->
    </content-with-sidebar>
</template>

<script>
import {
    BRow,
    BCol,
    BCard, BButton,
    BFormInput,
    BCardText,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BImg,
    BCardBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BPagination,
    BFormRadioGroup,
    BFormRadio,
} from 'bootstrap-vue'
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
// eslint-disable-next-line import/extensions
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ListCard from "@/views/pages/public/complaints/component/listCard";
import GridCard from "@/views/pages/public/complaints/component/gridCard";

export default {
    name: 'List',
    components: {
        GridCard,
        ListCard,
        BRow,
        BCol,
        BCard,
        BButton,
        BFormInput,
        BCardText,
        BCardBody,
        BCardTitle,
        BMedia,
        BAvatar,
        BMediaAside,
        BMediaBody,
        BLink,
        BBadge,
        BFormGroup,
        BInputGroup,
        BInputGroupAppend,
        BImg,
        BPagination,
        BFormRadioGroup,
        BFormRadio,
        Swiper,
        SwiperSlide,
        ContentWithSidebar,
    },
    data() {
        return {
            itemViewOptions: [
                {icon: 'GridIcon', value: 'grid-view'},
                {icon: 'ListIcon', value: 'list-view'},
            ],
            itemView: 'list-view',
            swiperOptions: {
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            },
            search_query: '',
            currentPage: 1,
            perPage: 50,
            rows: 140,
            complaintsStatus: [
                {title: 'submited', icon: 'FilePlusIcon'},
                {title: 'in_process', icon: 'ClockIcon'},
                {title: 'done', icon: 'CheckSquareIcon'},
                {title: 'canceled', icon: 'XCircleIcon'},
            ],
        }
    },
    computed: {
        complaints() {
            return this.$store.state.complaints.complaints
        },
    },
    watch: {
        currentPage: function (val) {
            this.getComplaints(val);
        },
    },
    created() {
        this.getComplaints();
    },
    methods: {
        statusColor(status) {
            if (status === 'in_process') return 'light-info'
            if (status === 'canceled') return 'light-danger'
            if (status === 'submited') return 'light-primary'
            // if (status === 'Video') return 'light-warning'
            if (status === 'done') return 'light-success'
            return 'light-primary'
        },

        getFilteredComplaints(event, status = null) {
            const myPayload = {
                productInPage: this.productInPage,
                currentPage: this.currentPage,
                keyword: this.search_query,
                status,
            }
            this.$store.dispatch('complaints/getComplaints', myPayload)
                .then(res => {

                })
                .catch(err => {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t('Error'),
                                icon: 'InfoIcon',
                                text: err.response.data.message,
                                variant: 'danger',
                            },
                        },
                        {
                            position: 'bottom-right',
                        })
                })
            console.log(`Get Complaints that has those keywords ${this.search_query}. And With ${status} Status.`)
        },
        getComplaints(page = 1) {
            this.$store.dispatch('complaints/getComplaints', {'currentPage': page,}).then(res => {
                this.currentPage = this.$store.state.complaints.complaintsPagination.page;
            })
                .catch(err => {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t('Error'),
                                icon: 'InfoIcon',
                                text: err.response.data.message ?? this.$t('Something went wrong'),
                                variant: 'danger',
                            },
                        },
                        {
                            position: 'bottom-right',
                        })
                })
        },
    },
}
</script>

<style lang="scss">
/*@import '../../../../@core/scss/vue/pages/page-blog.scss';*/
/*@import '~@core/scss/base/pages/page-blog.scss';*/
.card-img-top {
    height: 15em;
    object-fit: contain;
    object-position: center;
    border-radius: 12px;
    overflow: hidden;
}

@media screen and (max-width: 991px) {
    .blog-wrapper {
        display: flex;
        flex-wrap: wrap;

        .cws-sidebar {
            order: 1;
        }

        .cws-content {
            order: 2;
        }
    }
}

</style>
